import { FormState } from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse, Submission } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import {
  mapSubmissionToPartialBookRequest,
  mapFormSubmission,
} from '../../../../utils/mappers/form-submission.mapper';
import { DialogType, ReservedPaymentOptionIds } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import {
  bookingsContactInfoSaveSuccess,
  bookingsUouBookFlowBookingFormClickNext,
  bookingsUouBookFlowBookingFormClickNextFailure,
  bookingsPaymentMethodSelectionNextClicked,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { BookErrorType } from '../../../../types/errors';
import {
  CreateCheckoutResponse,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/http';
import { FormStatus } from '../../../../types/form-state';
import { CreateBookingResponse } from '@wix/ambassador-bookings-gateway/types';

export type OnSubmit = (submission: SubmissionResponse) => void;

enum FlowType {
  OWNER,
  CHOOSE_PLAN,
  SINGLE_SESSION,
  PREVIEW,
}

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async (submissionResponse) => {
    const [state] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter } = actionFactoryParams.context;
    const { status } = state;
    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        switch (getCurrentFlow(state, wixSdkAdapter)) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              submissionResponse.submission,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submissionResponse.submission,
            );
        }
      }
    }
  };
}

function getCurrentFlow(
  state: FormState,
  wixSdkAdapter: WixOOISDKAdapter,
): FlowType {
  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (
    state.selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan
  ) {
    return FlowType.CHOOSE_PLAN;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

export async function handleChoosePlanFlow(
  { getControllerState, context: { wixSdkAdapter } }: CreateActionParams,
  submission: Submission,
) {
  const [state, setState] = getControllerState();
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  const { service, slotAvailability, editorContext } = state;
  setFieldsValuesInStorage(wixSdkAdapter, submission);
  if (editorContext.isDummy) {
    await wixSdkAdapter.navigateToPricingPlanPreview();
  } else {
    await purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
  }
}

export async function handleOwnerFlow({
  getControllerState,
  context: { t },
}: CreateActionParams) {
  const [, setState] = getControllerState();
  setState(
    getOwnerSubmitDialog({
      contentText: t('app.dialog.owner-submit.content'),
      confirmButtonText: t('app.dialog.owner-submit.confirm-button'),
    }),
  );
}
export async function handlePreviewFlow({
  getControllerState,
  context: { wixSdkAdapter },
}: CreateActionParams) {
  const [state] = getControllerState();
  const { isBookingsOnEcom } = state;
  if (isBookingsOnEcom) {
    await wixSdkAdapter.openEcomCheckoutPreviewModal();
  } else {
    await wixSdkAdapter.navigateToBookingsWithSuffix();
  }
}

export async function handleSingleSessionFlow(
  {
    getControllerState,
    internalActions: { errorHandlers },
    context: { biLogger, wixSdkAdapter, formApi, reportError, experiments },
  }: CreateActionParams,
  submission: Submission,
) {
  const [state, setState] = getControllerState();
  const {
    pricingPlanDetails,
    memberships,
    service,
    businessInfo,
    couponInfo,
    slotAvailability,
    selectedPaymentOptionId,
    selectedPaymentType,
    isBookingsOnEcom,
  } = state;
  setState({
    status: FormStatus.PROCESSING_BOOK_REQUEST,
  });
  setFieldsValuesInStorage(wixSdkAdapter, submission);
  const isBookingsFormInternalApiErrorHandlerEnabled = experiments.enabled(
    'specs.bookings.BookingsFormInternalApiErrorHandler',
  );
  if (isBookingsOnEcom) {
    const selectedMembership = memberships?.eligibleMemberships?.find(
      (membership) => membership?.id === selectedPaymentOptionId,
    );
    try {
      const {
        contactDetails,
        additionalFields,
        numberOfParticipants,
        sendSmsReminder,
      } = mapFormSubmission(submission, service, businessInfo);
      const {
        createCheckoutResponse,
        createBookingResponse,
      } = await formApi.checkoutBooking({
        service,
        slot: slotAvailability.slot!,
        contactDetails,
        additionalFields,
        sendSmsReminder,
        numberOfParticipants,
        appliedCoupon: couponInfo.appliedCoupon,
        selectedMembership,
        selectedPaymentType,
        onError: (error) => {
          errorHandlers.addError(error);
          biLogger?.report(
            bookingsUouBookFlowBookingFormClickNextFailure({
              errorReason: error,
            }),
          );
        },
      });

      if (
        isBookingsFormInternalApiErrorHandlerEnabled &&
        !isSubmitValid(createCheckoutResponse, createBookingResponse)
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      }

      biLogger?.report(
        bookingsUouBookFlowBookingFormClickNext({
          smsNotificationRequest: sendSmsReminder,
          bookingId: createBookingResponse?.booking?.id,
          videoType:
            createBookingResponse?.booking?.onlineConference?.conferenceType,
        }),
      );

      if (isOnlineFlow(createCheckoutResponse)) {
        return wixSdkAdapter.navigateToEcomCheckoutPage({
          checkoutId: createCheckoutResponse!.checkout!.id!,
        });
      } else {
        return wixSdkAdapter.navigateToEcomThankYouPage({
          orderId: createCheckoutResponse!.orderId!,
        });
      }
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      reportError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsUouBookFlowBookingFormClickNextFailure({
          errorReason: error,
        }),
      );
    }
  } else {
    const selectedPlan = pricingPlanDetails?.plans?.find(
      (plan) => plan?.paidPlan?.orderId === selectedPaymentOptionId,
    );
    try {
      const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
        submission,
        service,
        businessInfo,
        slotAvailability?.slot?.timezone,
      );
      const bookingResponse = await formApi.book({
        service,
        formInfo,
        slotAvailability,
        selectedPlan,
        sendSmsReminder,
        appliedCoupon: couponInfo.appliedCoupon,
        onError: (error) => {
          errorHandlers.addError(error);
          biLogger?.report(
            bookingsPaymentMethodSelectionNextClicked({
              errorMessage: JSON.stringify(error),
            }),
          );
        },
      });

      if (
        isBookingsFormInternalApiErrorHandlerEnabled &&
        !bookingResponse?.booking
      ) {
        setState({ status: FormStatus.IDLE });
        return;
      }

      biLogger?.report(
        bookingsContactInfoSaveSuccess({
          smsNotificationRequest: sendSmsReminder,
        }),
      );

      delete bookingResponse.booking?.formInfo?.additionalFields;
      delete bookingResponse.booking?.formInfo?.customFormFields;

      return wixSdkAdapter.navigateToBookingsCheckout(
        bookingResponse.booking!,
        widgetDefaults.pageName,
      );
    } catch (error) {
      errorHandlers.addError(error as BookErrorType);
      setState({ status: FormStatus.IDLE });
      biLogger?.report(
        bookingsPaymentMethodSelectionNextClicked({
          errorMessage: JSON.stringify(error),
        }),
      );
      reportError(error as BookErrorType);
    }
  }
}

function isOnlineFlow(
  checkoutResponse: CreateCheckoutResponse | CreateOrderResponse,
): checkoutResponse is CreateCheckoutResponse {
  return (checkoutResponse as CreateCheckoutResponse)?.checkout !== undefined;
}

function getOwnerSubmitDialog({
  contentText,
  confirmButtonText,
}: {
  contentText: string;
  confirmButtonText: string;
}): Partial<FormState> {
  return {
    dialog: {
      type: DialogType.OwnerSubmit,
      props: {
        isOpen: true,
        contentText,
        confirmButtonText,
      },
    },
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { referral } = getQueryParams(wixSdkAdapter);

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone: slotAvailability.slot?.timezone,
      service: service.id,
    },
  });
}

export function getQueryParams(
  wixSdkAdapter: WixOOISDKAdapter,
): {
  [key: string]: string;
} {
  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );
  return { referral };
}
const isSubmitValid = (
  createCheckoutResponse: CreateCheckoutResponse | CreateOrderResponse,
  createBookingResponse: CreateBookingResponse,
) => {
  if (!createBookingResponse?.booking?.id) {
    return false;
  }

  if (isOnlineFlow(createCheckoutResponse)) {
    return createCheckoutResponse.checkout?.id !== undefined;
  }

  return createCheckoutResponse?.orderId !== undefined;
};
